import { Link } from "react-router-dom";
import styles from "../Home/Home.module.scss";
import MainWrapper from "../../components/MainWrapper/MainWrapper"
import Macquarie1 from "../../assets/projects/macquarie_1.jpg";
import LoanBase1 from "../../assets/projects/loanbase_1.jpg";
import Arthritis1 from "../../assets/projects/arthritis_1.jpg";
import NewsCorp1 from "../../assets/projects/newscorp_1.jpg";
import Adha1 from "../../assets/projects/adha_1.jpg";
import Mundi1 from "../../assets/projects/mundi_1.jpg";
import Fairfield1 from "../../assets/projects/fairfield_1.jpg";
import CWK1 from "../../assets/projects/cwk_1.jpg";
import Limbic1 from "../../assets/projects/thelimbic_1.jpg";
import CAC1 from "../../assets/projects/cac_1.jpg";
import ABCN1 from "../../assets/projects/abcn_1.jpg";
import DocumentTitle from "../../components/DocumentTitle/DocumentTitle";

const Project = (props) => {
  DocumentTitle("Jeff Lau - Projects");

  return (
    <>
      <section className={styles.mainTitle}>
        <MainWrapper size="10">
          <h1>Projects</h1>
        </MainWrapper>
      </section>
      <section className={styles.projects}>
        <MainWrapper size="10">
          <div className={styles.projectsContainer}>
            <div className={styles.twoColumn}>
              <div className={styles.leftColumn}>
                <Link to="/projects/macquarie" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="Macquarie1" src={Macquarie1} />
                  </div>
                  <h3>Macquarie Group</h3>
                  <p>Design System</p>
                </Link>
                <Link to="/projects/loan-base" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="LoanBase1" src={LoanBase1} />
                  </div>
                  <h3>Loan Base</h3>
                  <p>Design System</p>
                </Link>
                <Link to="/projects/adha" className={`${styles.project} ${styles.tall}`}>
                  <div className={styles.projectImage}>
                    <img alt="Adha1" src={Adha1} />
                  </div>
                  <h3>Australian Digital Health Agency</h3>
                  <p>Design System</p>
                </Link>
                {/*  */}
                <Link to="/projects/fairfield" className={`${styles.project} `}>
                  <div className={styles.projectImage}>
                    <img alt="Fairfield1" src={Fairfield1} />
                  </div>
                  <h3>Fairfield RSL</h3>
                  <p>Design System</p>
                </Link>
                <Link to="/projects/thecwk" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="CWK1" src={CWK1} />
                  </div>
                  <h3>The Company We Keep</h3>
                  <p>Design System</p>
                </Link>
                <Link to="/projects/the-limbic" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="Limbic1" src={Limbic1} />
                  </div>
                  <h3>The Limbic</h3>
                  <p>Design System</p>
                </Link>
              </div>
              <div className={styles.rightColumn}>
                <Link to="/projects/news-corp" className={`${styles.project} ${styles.tall}`}>
                  <div className={styles.projectImage}>
                    <img alt="NewsCorp1" src={NewsCorp1} />
                  </div>
                  <h3>News Corp</h3>
                  <p>Design System</p>
                </Link>
                <Link to="/projects/arthritis" className={`${styles.project}`}>
                  <div className={styles.projectImage}>
                    <img alt="Arthritis1" src={Arthritis1} />
                  </div>
                  <h3>Arthritis Australia</h3>
                  <p>Design System</p>
                </Link>
                <Link to="/projects/mundipharma" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="Mundi1" src={Mundi1} />
                  </div>
                  <h3>Mundipharma</h3>
                  <p>Design System</p>
                </Link>
                {/*  */}
                <Link to="/projects/abcn" className={`${styles.project} ${styles.tall}`}>
                  <div className={styles.projectImage}>
                    <img alt="ABCN1" src={ABCN1} />
                  </div>
                  <h3>Australian Business Community Network</h3>
                  <p>Design System</p>
                </Link>
                <Link to="/projects/campbelltown" className={`${styles.project} ${styles.medium}`}>
                  <div className={styles.projectImage}>
                    <img alt="CAC1" src={CAC1} />
                  </div>
                  <h3>Campbelltown Art Centre</h3>
                  <p>Design System</p>
                </Link>
              </div>
            </div>
          </div>
        </MainWrapper>
      </section>
    </>
  );
};

export default Project;
