import React, { useState } from "react"
import styles from "./Header.module.scss";
import Logo from "../../assets/Logo";
import MainWrapper from "../MainWrapper/MainWrapper";
import { Link } from "react-router-dom";
import ScrollPosition from "../ScrollPosition/ScrollPosition";

const Header = () => {
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 400;

  ScrollPosition(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const shadowStyle = shouldShowShadow ? styles.shadow : '';
  const hiddenStyle = shouldHideHeader ? styles.hidden : '';
  const mobileStyle = menuOpen ? styles.menuOpen : '';

  return (
    <>
      <header className={`${styles.header} ${shadowStyle} ${hiddenStyle} ${mobileStyle}`}>
        <MainWrapper>
          <nav>
            <Link to="/" className={styles.leftAlign}>
              <Logo className={styles.logo} />
            </Link>
            <div className={styles.rightAlign}>
              <ul className={styles.desktopMenu}>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/clients">Clients</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
              <div className={styles.mobileMenu}>
                <button onClick={toggleMenu} className={`${styles.hamburger} ${mobileStyle}`}>
                  <span className={styles.hamburgerBox}>
                    <span className={styles.hamburgerInner}></span>
                  </span>
                </button>
              </div>
            </div>
          </nav>
        </MainWrapper>
      </header>
      <div className={`${styles.mobileNavigation} ${mobileStyle}`}>
        <MainWrapper>
          <ul>
            <li><Link to="/projects">Projects</Link></li>
            <li><Link to="/clients">Clients</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </MainWrapper>
      </div>
    </>
  );
};

export default Header;
